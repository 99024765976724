import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Slider from "react-slick";
import { Container } from "reactstrap";

import Menu from "../Menus/Menu";
import MobileMenu from "../Menus/MobileMenu";
import "./styles.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restart: false,

      stikyClass: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === "set" && this.props.status !== prevProps.status) {
      this.setState({
        headerClass: prevProps.isHome ? "header" : "header inner",
        restart: true
      });
      window.scrollTo(0, 0);
    } else if (this.props.status === "set" && this.state.restart) {
      this.setState({
        restart: false
      });
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined")
      window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    if (typeof window !== "undefined")
      window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(e) {
    const currentHeight =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (currentHeight > 50 && !this.state.handleScroll) {
      this.setState({ handleScroll: true });
    } else if (currentHeight < 50 && this.state.handleScroll) {
      this.setState({ handleScroll: false });
    }
  }

  render() {
    const { head } = this.props.content;
    const settings = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 1800,
      fade: true,
      autoplay: head && head.length > 1,
      // autoplay: 0,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      customPaging: i => {
        // if (head && head.length > 0) {
        //   return (
        //     <a>
        //       <span className={'title'}>{head[i].title}</span>
        //       {/* <span className={'number'}>{`0${i + 1}`}</span> */}
        //       <span className={'subtitle'}>{head[i].sub_title}</span>
        //     </a>
        //   );
        // } else {
        // return <a />;
        // }
      }
    };
    return (
      <div className={`headerWrap ${!this.props.isHome && "not-home"}`}>
        <div
          className={`menus clearfix ${this.state.handleScroll ? "fixed" : ""}`}
        >
          <Container>
            <MobileMenu
              isHome={this.props.isHome}
              menu={this.props.topmenu}
              location={this.props.current}
              submenu={this.props.submenu}
              lang={this.props.lang}
            />

            <Menu
              isHome={this.props.isHome}
              content={this.props.topmenu}
              location={this.props.current}
              lang={this.props.lang}
            />
          </Container>
        </div>
        <div className={"full-height"}>
          <div className={`${this.props.isHome ? "header" : "header inner"} `}>
            {(this.props.isHome ||
              (!this.props.isHome && head && !head[0].title)) && (
              <div className={"overlay-bars"} />
            )}
            {head && !this.state.restart && (
              <Slider {...settings}>
                {head.map(
                  (slide, index) =>
                    slide.slide && (
                      <div key={index} className={"header-wrapper"}>
                        <div
                          className={"header-slide"}
                          style={{
                            backgroundImage: `url('${slide.slide.sizes &&
                              slide.slide.sizes.large}')`
                          }}
                        />
                        {slide.title && (
                          <div className={"box-elemtns"}>
                            <div className={"content-wrap"}>
                              {slide.sub_title && (
                                <h3
                                  dangerouslySetInnerHTML={{
                                    __html: slide.sub_title
                                  }}
                                />
                              )}
                              {slide.title && (
                                <h2
                                  dangerouslySetInnerHTML={{
                                    __html: slide.title
                                  }}
                                />
                              )}
                              {slide.text && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: slide.text
                                  }}
                                />
                              )}
                              {slide.button && (
                                <a className={"action"} href={slide.link}>
                                  {slide.button}
                                </a>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )
                )}
              </Slider>
            )}
            <div className={"logo-bars"}>
              <span />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  topmenu: state.menus.topmenu,
  submenu: state.menus.submenu,

  status: state.content.status,
  content: state.content.currentContent && state.content.currentContent.acf
});

const mapDispatchToProps = dispatch => bindActionCreators({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

Header.propTypes = {
  content: PropTypes.object,
  topmenu: PropTypes.array,
  submenu: PropTypes.array
};

Header.defaultProps = {
  content: {
    slides: []
  },
  topmenu: [],
  submenu: []
};
