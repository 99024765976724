import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './styles.css';
import LayoutLogoRow from '../LayoutLogoRow';
import logo from '../../assets/logo.svg';

export default class Contact extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={'footer'}>
        <Container>
          <Col xs="12" md="12">
            {this.props.content && (
              <LayoutLogoRow content={this.props.content.sponsors} />
            )}
          </Col>
          <Row>
            <Col xs="12" md="12">
              <div className={'wrapper'}>
                © {new Date().getFullYear()} Aquasado. Todos os direitos
                reservados, developed in collaboration with{' '}
                <a href="https://cors.digital/"> CORS Digital</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
