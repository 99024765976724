import React from 'react';
import { Link } from 'react-router-dom';
import Language from '../../../containers/Language';
import { scrollToItem } from '../../../utils/scroll';
import './styles.css';

function Menu(props) {
  function scrollToReservation(link) {
    if (typeof window !== 'undefined') {
      const item = document.getElementById(link);
      scrollToItem(item, 150);
    }
  }

  function renderLink(col) {
    if (col.link[0] === '/') {
      return (
        <Link
          to={col.link}
          className={props.location.match(col.link) ? 'active' : 'notactive'}
          dangerouslySetInnerHTML={{ __html: col.title }}
        />
      );
    } else if (!props.isHome) {
      return (
        <Link
          to={`/#${col.link}`}
          className={props.location.match(col.link) ? 'active' : 'notactive'}
          dangerouslySetInnerHTML={{ __html: col.title }}
        />
      );
    } else {
      return (
        <a
          href={`#`}
          className={props.location.match(col.link) ? 'active' : 'notactive'}
          onClick={() => scrollToReservation(col.link)}
          dangerouslySetInnerHTML={{ __html: col.title }}
        />
      );
    }
  }

  return (
    <ul className={'wrap clearfix'}>
      <li
        className={'logo fadeIn animated'}
        style={{
          animationDelay: `1s`,
          animationDuration: `2s`,
        }}
      >
        <Link to={''} />
      </li>
      <li className={'menu-items'}>
        <ul>
          {props.content &&
            props.content.map((col, index) => (
              <li
                key={index}
                className={'fadeIn animated'}
                style={{
                  animationDelay: `${index + 0.1}s`,
                  animationDuration: `2s`,
                }}
              >
                {renderLink(col)}
              </li>
            ))}
          <li>
            <Language language={props.lang} location={props.location} />
          </li>
        </ul>
      </li>
    </ul>
  );
}

export default Menu;
