import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.css';
import Title from '../Title';
import Slider from 'react-slick';

const Show = props => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

function LayoutLogoRow(props) {
  if (!props.content) return null;

  const settings = {
    dots: true,
    autoplay: true,
    autoplay: props.content.length > 4,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: props.content.length > 4 ? 4 : props.content.length,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          autoplay: props.content.length > 2 ? 2 : props.content.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplay: props.content.length > 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`logo-row ${props.first ? 'first' : ''}`}>
      <div className={'title'}>
        <span>{props.title}</span>
      </div>
      <Container>
        <div className={'content-wrap'}>
          <Slider {...settings}>
            {props.content.map((row, i) => {
              const partner = row.partner;
              return (
                partner.image && (
                  <a
                    href={partner.link}
                    key={i}
                    target="_blank"
                    className={'image'}
                  >
                    <img
                      src={partner.image.url && partner.image.url}
                      alt={partner.image.alt}
                    />
                    {partner.label !== '' && (
                      <span className={'partner-label'}>{partner.label}</span>
                    )}
                  </a>
                )
              );
            })}
          </Slider>
        </div>
      </Container>
    </div>
  );
}

export default LayoutLogoRow;
